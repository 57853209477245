import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { currentChannel: '1', currentStay: 1, channels: [] }; // 액션들의 집합.
export var actions = { SET_CHANNEL: 'SET_CHANNEL', SET_STAY: 'SET_STAY', REQUEST_GET_CHANNELS: 'REQUEST_GET_CHANNELS', SUCCESS_GET_CHANNELS: 'SUCCESS_GET_CHANNELS', FAILURE_GET_CHANNELS: 'FAILURE_GET_CHANNELS', REQUEST_SYNC_CHANNEL: 'REQUEST_SYNC_CHANNEL', SUCCESS_SYNC_CHANNEL: 'SUCCESS_SYNC_CHANNEL', FAILURE_SYNC_CHANNEL: 'FAILURE_SYNC_CHANNEL' };
export var setChannelAction = function setChannelAction(channel) { return { type: actions.SET_CHANNEL, channel: channel }; };
export var setStayAction = function setStayAction(stay) { return { type: actions.SET_STAY, stay: stay }; };
export var getChannelsAction = function getChannelsAction(id) { return { type: actions.REQUEST_GET_CHANNELS, id: id }; };
export var syncChannelAction = function syncChannelAction(id, channel) { return { type: actions.REQUEST_SYNC_CHANNEL, id: id, channel: channel }; }; // 액션 크리에이터. dispatch 내부에서 사용.
// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, function (draft) { switch (action.type) {
    case actions.SET_CHANNEL:
        draft.currentChannel = action.channel;
        break;
    case actions.SET_STAY:
        draft.currentStay = action.stay;
        break;
    default: break;
} }); };
export default reducer;
